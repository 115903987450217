import revive_payload_client_ZSVhORtdJm from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_bxyj6urenj6chw5x76ci5zfe54/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import path_client_2CrkUkjKpA from "/home/runner/work/octo/octo/plugins/path.client.ts";
import unhead_K6n2teXq2X from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_bxyj6urenj6chw5x76ci5zfe54/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UNsIQ2jEqp from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_bxyj6urenj6chw5x76ci5zfe54/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ibrlwCKO0X from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_bxyj6urenj6chw5x76ci5zfe54/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BtK0WuKunW from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_bxyj6urenj6chw5x76ci5zfe54/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kn690berlD from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_bxyj6urenj6chw5x76ci5zfe54/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Xuml7AermY from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_bxyj6urenj6chw5x76ci5zfe54/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_zXaOIVVkUS from "/home/runner/work/octo/octo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@2.79.2_typescript@5.3.3_vue@3.5.3_typescript@5.3.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/octo/octo/.nuxt/components.plugin.mjs";
import prefetch_client_CTyc374Wjs from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_bxyj6urenj6chw5x76ci5zfe54/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_tt31VrFxGS from "/home/runner/work/octo/octo/node_modules/.pnpm/@vite-pwa+nuxt@0.4.0_magicast@0.3.5_rollup@2.79.2_vite@5.4.8_@types+node@18.19.4_terser@5.34._ge23qdvzldwiyj6dmf3m6qibim/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import dev_Zc585R5Y2V from "/home/runner/work/octo/octo/plugins/dev.ts";
import mq_9h3c4HK3CS from "/home/runner/work/octo/octo/plugins/mq.ts";
import plugins_gZVkQZouNK from "/home/runner/work/octo/octo/plugins/plugins.ts";
import vuex_F8JCgj45hr from "/home/runner/work/octo/octo/plugins/vuex.ts";
export default [
  revive_payload_client_ZSVhORtdJm,
  path_client_2CrkUkjKpA,
  unhead_K6n2teXq2X,
  router_UNsIQ2jEqp,
  payload_client_ibrlwCKO0X,
  navigation_repaint_client_BtK0WuKunW,
  check_outdated_build_client_kn690berlD,
  chunk_reload_client_Xuml7AermY,
  plugin_vue3_zXaOIVVkUS,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CTyc374Wjs,
  pwa_client_tt31VrFxGS,
  dev_Zc585R5Y2V,
  mq_9h3c4HK3CS,
  plugins_gZVkQZouNK,
  vuex_F8JCgj45hr
]