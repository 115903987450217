import validate from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_bxyj6urenj6chw5x76ci5zfe54/node_modules/nuxt/dist/pages/runtime/validate.js";
import analytics_45global from "/home/runner/work/octo/octo/middleware/analytics.global.ts";
import redirects_45global from "/home/runner/work/octo/octo/middleware/redirects.global.ts";
import manifest_45route_45rule from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_bxyj6urenj6chw5x76ci5zfe54/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  analytics_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}